import { createContext } from "react";
import { KeyValueObject } from "../types/shared";

export interface AuthContextProps {
  isLoggedin: boolean;
  csrf: string;
  invoices: boolean;
  cash: number;
  currency: string;
  location: string | undefined;
  reports: KeyValueObject<any>[];
  isLabel?: boolean;
  dueDate?: number;
  revAccessToken?: string | null;
  errors?: any;
  setValue: (
    newValue: Partial<Omit<AuthContextProps, "signOut" | "signIn">>,
  ) => void;
  signOut: () => void;
  signIn: (revAccessToken?: string | null) => void;
}

export const authProviderInitValue = {
  isLoggedin: false,
  csrf: "",
  invoices: false,
  cash: 0,
  currency: "USD",
  location: "am",
  reports: [],
  errors: null,
  revAccessToken: null,
  setValue: () => {},
  signOut: () => {},
  signIn: () => {},
};

const AuthContext = createContext<AuthContextProps>(authProviderInitValue);

export const AuthProvider = AuthContext.Provider;

export default AuthContext;
